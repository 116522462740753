<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Pedidos</h4>
            <div class="small text-muted">Administración de todos los pedidos ingresados al sistema</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>            
            <b-col lg="8">
              <b-row>                        
                <b-col lg="6">
                  <Widget :configWidget="configWidget" reference="indicator_total_amount_orders_today" :hasAccess="configWidget.elements.indicatorTotalAmountOrdersToday" />                            
                </b-col>            
                <b-col lg="6">                        
                  <Widget :configWidget="configWidget" reference="indicator_total_orders_today" :hasAccess="configWidget.elements.indicatorTotalOrdersToday" />                            
                </b-col>
                <b-col lg="12">                        
                  <Widget :configWidget="configWidget" reference="bar_order_sales_months" :hasAccess="configWidget.elements.barOrderSalesMonths" />                            
                </b-col>                
              </b-row>
            </b-col>
            <b-col lg="4">
              <Widget :configWidget="configWidget" reference="pie_orders_status" :hasAccess="configWidget.elements.pieOrdersStatus" />                            
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3">
        
          <b-list-group v-if="isProducts && !isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openOrders()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Generar</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Crear y administrar todos los pedidos emitidos y recibidos                
              </p>
            </b-list-group-item>
          </b-list-group>       

          <b-list-group v-if="isProducts && isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openOrdersSellers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Mis Pedidos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de todos mis pedidos emitidos y recibidos
              </p>
            </b-list-group-item>
          </b-list-group>    

          <b-list-group v-if="isCourses && !isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openOrderCourses()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Ventas de Cursos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de todos las ventas de cursos recibidas
              </p>
            </b-list-group-item>
          </b-list-group>  

          <b-list-group v-if="!isSeller" class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openConfiguration()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuración</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuracón del módulo
              </p>
            </b-list-group-item>
          </b-list-group>
                  
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalOrdersToday: true,            
            pieOrdersStatus: true,
            indicatorTotalAmountOrdersToday: true,
            barOrderSalesMonths: true,
          }
        },
        primaryColor: '',
        modulesActive: [],
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalAmountOrdersToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_amount_orders_today')            
      this.configWidget.elements.indicatorTotalOrdersToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_orders_today')      
      this.configWidget.elements.barOrderSalesMonths = Helper.hasAccessWidget(this.configWidget, 'bar_order_sales_months')      
      this.configWidget.elements.pieOrdersStatus = Helper.hasAccessWidget(this.configWidget, 'pie_orders_status')            
      /* Fin configuracion */
    },
    mounted() {
      this.modulesActive = Session.getSession().auth.user.permissions.modules

      this.setup()
    }, 
    computed: {
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      },

      isProducts() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.PRODUCTOS) {
            status = true
          }
        })
        return status
      },
      isCourses() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.CURSOS) {
            status = true
          }
        })
        return status
      }
    },     
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openOrders() {
        this.$router.push({ name: 'OrdersStaffCrudOrders' })
      },

      openOrderCourses() {
        this.$router.push({ name: 'OrdersStaffCrudOrdersCourses' })
      },

      openConfiguration() {
        this.$router.push({ name: 'OrdersStaffConfiguration' })
      },    
      
      openOrdersSellers() {
        this.$router.push({ name: 'OrdersStaffCrudOrdersSellers' })
      }
    }    
   
  }
</script>

<style>

</style>
